<template>
  <section id="populer-products" class="populer-products">
    <div class="container">
      <div class="populer-products-content">
        <div class="row">
          <div
            class="col-md-3"
            v-for="product in getPopularProducts()"
            :key="product"
          >
            <div class="single-populer-products">
              <div class="single-populer-product-img mt40">
                <img :src="product.image" :alt="product.name" />
              </div>
              <h2>
                <a target="_blank" :href="product.brochure">{{
                  product.name
                }}</a>
              </h2>
              <div class="single-populer-products-para">
                <p v-html="product.description"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/.container-->
  </section>
  <!--/.populer-products-->
</template>

<script setup>
const { useProductStore } = require("@/stores/products.store");
const { storeToRefs } = require("pinia");

const { getPopularProducts } = storeToRefs(useProductStore());
</script>