<template>
  <HeaderComponent></HeaderComponent>
  <PopularProductsComponent></PopularProductsComponent>
  <NewArrivalsComponent
    v-for="category in categories"
    :key="category"
    :categoryName="category.name"
    :categoryId="category.id"
  ></NewArrivalsComponent>

  <!-- <SofaCollectionsComponent></SofaCollectionsComponent> -->
  <!-- <FeatureProductsComponent></FeatureProductsComponent> -->
  <NewsLetterComponent></NewsLetterComponent>
  <FooterComponent></FooterComponent>
</template>

<script setup>
import HeaderComponent from "./components/HeaderComponent.vue";
import PopularProductsComponent from "./components/PopularProductsComponent.vue";
import NewArrivalsComponent from "./components/NewArrivalsComponent.vue";
// import SofaCollectionsComponent from "./components/SofaCollectionsComponent.vue";
// import FeatureProductsComponent from "./components/FeatureProductsComponent.vue";
import NewsLetterComponent from "./components/NewsLetterComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";
import { useProductStore } from "./stores/products.store";
import { storeToRefs } from "pinia";

const { fetchProducts, fetchCategories } = useProductStore();
const { categories } = storeToRefs(useProductStore());

fetchCategories();
fetchProducts();
</script>