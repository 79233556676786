import { createClient } from "@supabase/supabase-js";
import { defineStore } from "pinia";

const supabaseUrl = "https://santufkeddhvwbxinzdl.supabase.co";
const supabaseKey =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNhbnR1ZmtlZGRodndieGluemRsIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTM2NDQzMTIsImV4cCI6MTk2OTIyMDMxMn0.ZgByy-5787fPmMKv-X5uNjWaXl_r_T4RvWAHFFoye1c";
const supabase = createClient(supabaseUrl, supabaseKey);


export const useProductStore = defineStore({
    id: "product",
    state: () => ({
        products: [],
        categories: []
    }),
    getters: {
        getProductsForSlider: (state) => {
            return () => state.products.filter((p) => p.slider === true);
        },
        getPopularProducts: (state) => {
            return () => state.products.filter((p) => p.popular === true).slice(0, 4);
        },
        getProductsByCategoryId: (state) => {
            return (categoryId) => state.products.filter((p) => p.Category === categoryId);
        }
    },
    actions: {
        async fetchProducts() {
            this.products = []

            try {
                let { data: Products } = await supabase.from("Products").select("*").order("Priority");
                this.products = Products
            } catch (error) {
                console.log("Error fetching products: " + error)
            }
        },
        async fetchCategories() {
            this.categories = []

            try {
                let { data: Categories } = await supabase.from("Categories").select("*").order("Priority");
                this.categories = Categories
            } catch (error) {
                console.log("Error fetching categories: " + error)
            }
        }
    }
})