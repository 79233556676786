<template>
  <header id="home" class="welcome-hero">
    <div
      id="header-carousel"
      class="carousel slide carousel-fade"
      data-ride="carousel"
    >
      <!--/.carousel-indicator -->
      <ol class="carousel-indicators">
        <li
          v-for="(product, index) in getProductsForSlider()"
          :key="product"
          data-target="#header-carousel"
          v-bind:data-slide-to="index"
          v-bind:class="{ active: index === 0 }"
        >
          <span class="small-circle"></span>
        </li>
      </ol>
      <!-- /ol-->
      <!--/.carousel-indicator -->

      <!--/.carousel-inner -->
      <div class="carousel-inner" role="listbox">
        <div
          class="item"
          v-for="(product, index) in getProductsForSlider()"
          :key="product"
          v-bind:class="{ active: index === 0 }"
        >
          <div class="single-slide-item slide3">
            <div class="container">
              <div class="welcome-hero-content">
                <div class="row">
                  <div class="col-sm-7">
                    <div class="single-welcome-hero">
                      <div class="welcome-hero-txt">
                        <!-- <h4>Autoref Justice AR 860</h4> -->
                        <h2>{{ product.name }}</h2>
                        <p v-html="product.description"></p>
                        <div class="packages-price" style="display: none">
                          <p>
                            $ 299.00
                            <del>$ 399.00</del>
                          </p>
                        </div>
                        <a :href="product.brochure" target="_blank">
                          <button class="btn-cart welcome-add-cart">
                            Brochure
                          </button>
                        </a>
                        <button
                          class="btn-cart welcome-add-cart welcome-more-info"
                          onclick="window.location.href='#'"
                          style="display: none"
                        >
                          more info
                        </button>
                      </div>
                      <!--/.welcome-hero-txt-->
                    </div>
                    <!--/.single-welcome-hero-->
                  </div>
                  <!--/.col-->
                  <div class="col-sm-5">
                    <div class="single-welcome-hero">
                      <div class="welcome-hero-img">
                        <img :src="product.image" :alt="product.name" />
                      </div>
                      <!--/.welcome-hero-txt-->
                    </div>
                    <!--/.single-welcome-hero-->
                  </div>
                  <!--/.col-->
                </div>
                <!--/.row-->
              </div>
              <!--/.welcome-hero-content-->
            </div>
            <!-- /.container-->
          </div>
          <!-- /.single-slide-item-->
        </div>
        <!-- /.item .active-->
      </div>
      <!-- /.carousel-inner-->
    </div>
    <!--/#header-carousel-->

    <!-- top-area Start -->
    <div class="top-area">
      <div class="header-area">
        <!-- Start Navigation -->
        <nav
          class="navbar navbar-default bootsnav navbar-sticky navbar-scrollspy"
          data-minus-value-desktop="70"
          data-minus-value-mobile="55"
          data-speed="1000"
        >
          <!-- Start Top Search -->
          <div class="top-search">
            <div class="container">
              <div class="input-group">
                <span class="input-group-addon"
                  ><i class="fa fa-search"></i
                ></span>
                <input type="text" class="form-control" placeholder="Search" />
                <span class="input-group-addon close-search"
                  ><i class="fa fa-times"></i
                ></span>
              </div>
            </div>
          </div>
          <!-- End Top Search -->

          <div class="container">
            <!-- Start Header Navigation -->
            <div class="navbar-header">
              <button
                type="button"
                class="navbar-toggle"
                data-toggle="collapse"
                data-target="#navbar-menu"
              >
                <i class="fa fa-bars"></i>
              </button>
              <a class="navbar-brand" href="index.html"
                >Milan<span>Optical</span>Devices</a
              >
            </div>
            <!--/.navbar-header-->
            <!-- End Header Navigation -->

            <!-- Collect the nav links, forms, and other content for toggling -->
            <div
              class="collapse navbar-collapse menu-ui-design"
              id="navbar-menu"
            >
              <ul
                class="nav navbar-nav navbar-center"
                data-in="fadeInDown"
                data-out="fadeOutUp"
              >
                <li class="scroll active"><a href="#home">home</a></li>
                <li class="scroll"><a href="#new-arrivals">new arrival</a></li>
                <li class="scroll"><a href="#feature">features</a></li>
                <!-- <li class="scroll"><a href="#blog">blog</a></li> -->
                <li class="scroll"><a href="#newsletter">contact</a></li>
              </ul>
              <!--/.nav -->
            </div>
            <!-- /.navbar-collapse -->
          </div>
          <!--/.container-->
        </nav>
        <!--/nav-->
        <!-- End Navigation -->
      </div>
      <!--/.header-area-->
      <div class="clearfix"></div>
    </div>
    <!-- /.top-area-->
    <!-- top-area End -->
  </header>
  <!--/.welcome-hero-->
</template>

<script setup>
import { useProductStore } from "@/stores/products.store";
import { storeToRefs } from "pinia";

const { getProductsForSlider } = storeToRefs(useProductStore())
</script>