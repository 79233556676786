<template>
  <section id="newsletter" class="newsletter">
    <div class="container">
      <div class="hm-footer-details">
        <div class="row">
          <div class="col-md-3 col-sm-6 col-xs-12">
            <div class="hm-footer-widget">
              <div class="hm-foot-title">
                <h4>information</h4>
              </div>
              <!--/.hm-foot-title-->
              <div class="hm-foot-menu">
                <ul>
                  <li><a href="#">about us</a></li>
                  <!--/li-->
                  <li><a href="#">contact us</a></li>
                  <!--/li-->
                  <li><a href="#">news</a></li>
                  <!--/li-->
                  <li><a href="#">store</a></li>
                  <!--/li-->
                </ul>
                <!--/ul-->
              </div>
              <!--/.hm-foot-menu-->
            </div>
            <!--/.hm-footer-widget-->
          </div>
          <!--/.col-->
          <div class="col-md-3 col-sm-6 col-xs-12" style="display: none">
            <div class="hm-footer-widget">
              <div class="hm-foot-title">
                <h4>collections</h4>
              </div>
              <!--/.hm-foot-title-->
              <div class="hm-foot-menu">
                <ul>
                  <li><a href="#">wooden chair</a></li>
                  <!--/li-->
                  <li><a href="#">royal cloth sofa</a></li>
                  <!--/li-->
                  <li><a href="#">accent chair</a></li>
                  <!--/li-->
                  <li><a href="#">bed</a></li>
                  <!--/li-->
                  <li><a href="#">hanging lamp</a></li>
                  <!--/li-->
                </ul>
                <!--/ul-->
              </div>
              <!--/.hm-foot-menu-->
            </div>
            <!--/.hm-footer-widget-->
          </div>
          <!--/.col-->
          <div class="col-md-3 col-sm-6 col-xs-12" style="display: none">
            <div class="hm-footer-widget">
              <div class="hm-foot-title">
                <h4>my accounts</h4>
              </div>
              <!--/.hm-foot-title-->
              <div class="hm-foot-menu">
                <ul>
                  <li><a href="#">my account</a></li>
                  <!--/li-->
                  <li><a href="#">wishlist</a></li>
                  <!--/li-->
                  <li><a href="#">Community</a></li>
                  <!--/li-->
                  <li><a href="#">order history</a></li>
                  <!--/li-->
                  <li><a href="#">my cart</a></li>
                  <!--/li-->
                </ul>
                <!--/ul-->
              </div>
              <!--/.hm-foot-menu-->
            </div>
            <!--/.hm-footer-widget-->
          </div>
          <!--/.col-->
          <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="hm-footer-widget">
              <div class="hm-foot-title">
                <h4>Contact Us</h4>
              </div>
              <!--/.hm-foot-title-->
              <div class="hm-foot-para">
                <h3>Milan Optical Devices</h3>
                <br />
                <p>
                  GF 10/11, Sunrise Complex, Near Hanuman Mandir, Raopura,
                  Vadodara.
                </p>
                <p>
                  <a href="https://www.indiamart.com/milanopticaldevices/"
                    >India Mart</a
                  >
                  <br/><a href="mailto:milanopticaldevices@gmail.com"
                    >milanopticaldevices@gmail.com</a
                  >
                  <br /><a href="tel:+919913884477">
                    Call us at +91 9913884477
                  </a>
                </p>
              </div>
              <!--/.hm-foot-para-->
              <div class="hm-foot-email">
                <div class="foot-email-box">
                  <button
                    class="form-control"
                    onclick="window.open('https://wa.me/919913884477')"
                  >
                    Message us on WhatsApp
                  </button>
                </div>
                <!--/.foot-email-box-->
                <div class="foot-email-subscribe">
                  <span><i class="fa fa-whatsapp"></i></span>
                </div>
                <!--/.foot-email-icon-->
              </div>
              <!--/.hm-foot-email-->
            </div>
            <!--/.hm-footer-widget-->
          </div>
          <!--/.col-->
        </div>
        <!--/.row-->
      </div>
      <!--/.hm-footer-details-->
    </div>
    <!--/.container-->
  </section>
  <!--/newsletter-->
</template>