<template>
  <section id="new-arrivals" class="new-arrivals">
    <div class="container">
      <div class="section-header">
        <h2>{{props.categoryName}}</h2>
      </div>
      <!--/.section-header-->
      <div class="new-arrivals-content">
        <div class="row">
          <div class="col-md-3 col-sm-4" v-for="product in getProductsByCategoryId(categoryId)" :key="product">
            <div class="single-new-arrival">
              <div class="single-new-arrival-bg">
                <img
                  :src="product.image"
                  :alt="product.name"
                />
                <div class="single-new-arrival-bg-overlay"></div>
                <!-- <div class="sale bg-1">
                  <p>sale</p>
                </div> -->
                <div class="new-arrival-cart">
                  <p>
                    <a :href="product.brochure" target="_blank">Brochure</a>
                  </p>
                  <!-- <p class="arrival-review pull-right">
                    <span class="lnr lnr-heart"></span>
                    <span class="lnr lnr-frame-expand"></span>
                  </p> -->
                </div>
              </div>
              <h4><a :href="product.brochure" target="_blank">{{product.name}}</a></h4>
              <!-- <p class="arrival-product-price">$140.00</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/.container-->
  </section>
  <!--/.new-arrivals-->
</template>

<script setup>
import { useProductStore } from "@/stores/products.store";
import { storeToRefs } from "pinia";
import { defineProps } from "vue"; 

const props = defineProps({
  categoryName: String,
  categoryId: Number
})

const { getProductsByCategoryId } =  storeToRefs(useProductStore())

</script>